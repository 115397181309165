import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Wrapper from "../../layout/wrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppConfigEvent } from "../../redux/types/redux-event";
import {
  cognicuecandidateinterview,
  addQuickCandidateInterview,
  quickInterview,
} from "../../services/quick-interview";

import {
  quickInterviewToken,
  getQuickInterviewDetailsbyId,
} from "../../services/quick-interview";
import config from "../../services/config";
import { getDataFromLocalStorage } from "../../browser-storage";
import styled from "styled-components";
import ReactPlayer from "react-player";
import "./studentinteractivedashboard.css";
import {
  AddCandidateAttemptedInterview,
  GenerateAIInterviewSSOToken,
  getAIInterviewAttemptedList,
  getAIInterviewByHostname,
} from "../../services/ai-interview";
import Card from "react-bootstrap/Card";

const QuickInterview = (res) => {
  let candidateEmail = "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isInterviewCompleted, setisInterviewCompleted] = useState("false");
  const [current_student_details, setcurrentStudentDetails] = useState();
  const location = useLocation();

  useEffect(() => {
    // Function to check if the URL contains a JWT token
    const hasJwtToken = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.has("jwt");
    };

    // Check if the URL contains a JWT token and perform actions accordingly
    if (hasJwtToken()) {
      saveInterview();
    }
  }, []); // The empty dependency array ensures that this effect runs only once after the component mounts

  let saveInterview = async () => {
    try {
      const searchparam = location.search;
      const jwttoken = searchparam.slice(5);

      if (jwttoken) {
        const res = await quickInterview(jwttoken);
        console.log("res", res);

        const payloadobj = JSON.parse(JSON.stringify(res));
        const candidate_interview_id = payloadobj.candidate_interview_id;
        candidateEmail = payloadobj.candidate_email;
        console.log(candidate_interview_id);

        if (res) {
          const new_resp = await cognicuecandidateinterview(
            candidate_interview_id
          )
            .then(async (resp) => {
              console.log("cognicuecandidateinterview", resp);
              const candidate_email = candidateEmail;
              const interview_score = resp.interviews[0].interview_score;
              const interview_id = resp.interviews[0].interview_id;
              const interview_name = resp.interviews[0].interview_name;
              const interview_status = resp.interviews[0].interview_status;
              const interview_url = resp.interviews[0].interview_url;
              const candidate_id = resp.candidate_id;
              const candidate_analytics_url = resp.interviews[0].analytics_url;

              console.log("candidate_email", candidate_email);
              console.log("interview_score", interview_score);
              console.log("candidate_id", candidate_id);
              console.log("candidate_interview_id", candidate_interview_id);

              const payload = {
                candidate_email: candidate_email,
                interview_score: interview_score,
                candidate_id: candidate_id,
                candidate_interview_id: candidate_interview_id,
                candidate_analytics_url: candidate_analytics_url,
                interview_id: interview_id,
                interview_name: interview_name,
                interview_status: interview_status,
                interview_url: interview_url,
              };
              // To Store the Interview Details
              const finalresp = await AddCandidateAttemptedInterview(payload)
                .then((resp) => {
                  console.log("AddCandidateAttemptedInterview", resp);

                  toast.success(`AI Interview Successfully Completed`, {
                    position: "top-left",
                  });

                  const newUrl =
                    "https://mygreenhorn.com/dashboard/quick-interview"; // Replace with your static URL
                  // Update the URL without the token
                  window.history.replaceState({ path: newUrl }, "", newUrl);

                  // Reload the page
                  window.location.reload();
                })
                .catch((err) => {
                  console.log("err", err);
                });
              console.log(finalresp);
            })
            .catch((err) => {
              console.log("err", err);
            });
          console.log(new_resp);
        }
      }
    } catch (err) {
      toast.error(`Error : ${err.response.data}`, {
        position: "top-left",
      });
    }
  };

  //This is just to start the Interview Process
  // const startAIinterview = () => {
  //   quickInterviewToken().then((token) => {
  //     window.location.href = config.COGNICUE_CREATE_STUDENT_INTERVIEW + token;
  //   });
  // };
  const startAIinterview = (currentElement) => {
    console.log("currentElement", currentElement);
    const payload = {
      interviewId: currentElement.interviewId,
      current_hostname:
        "https://" + window.location.hostname + "/dashboard/quick-interview",
      //https://qa.mygreenhorn.com/
    };
    GenerateAIInterviewSSOToken(payload).then((token) => {
      window.location.href = config.COGNICUE_CREATE_STUDENT_INTERVIEW + token;
    });
  };

  useEffect(() => {
    getAIInterviewByHostnameFunc();
    checkStudentAttemptedInterviewFunc();
  }, []);

  const [interviewList, setinterviewList] = useState([]);
  const [studentattemptedinterview, setAttemptedList] = useState([]);
  const [attemptedlength, setattemptedlength] = useState(0);

  const getAIInterviewByHostnameFunc = async () => {
    const res = await getAIInterviewByHostname(window.location.hostname)
      .then((resp) => {
        console.log(resp);
        const list = resp;
        setinterviewList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkStudentAttemptedInterviewFunc = async () => {
    const userDetials = getDataFromLocalStorage("user");
    const email = userDetials.email;
    const student_emailId = email;
    const res = await getAIInterviewAttemptedList(student_emailId)
      .then((resp) => {
        console.log(resp);
        const list = resp;
        const listLength = list.length;
        console.log("listLength", listLength);
        setAttemptedList(list);
        setattemptedlength(listLength);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    CompareandCreateFinalArray();
  }, [interviewList, studentattemptedinterview]);

  const [finalArray, setFinalArray] = useState([]);

  const CompareandCreateFinalArray = async () => {
    const newArray = interviewList.map((item1) => {
      // Check if there's any element in studentattemptedinterview with a matching interviewId
      const item2 = studentattemptedinterview.find(
        (item2) => item2.interviewId === item1.interviewId
      );

      if (item2) {
        // Add specific fields from item2 to item1 if a match is found
        const {
          candidate_analytics_url,
          candidate_email,
          candidate_id,
          candidate_interview_id,
          hostname_registered,
        } = item2;

        return {
          ...item1,
          InterviewAttempted: true,
          candidate_analytics_url,
          candidate_email,
          candidate_id,
          candidate_interview_id,
          hostname_registered,
        };
      } else {
        return {
          ...item1,
          InterviewAttempted: false,
        };
      }
    });

    console.log("newArray", newArray);
    setFinalArray(newArray);
  };
  const accessAIInterviewReport = async (currentElement) => {
    console.log(currentElement);
    const current_report_url = currentElement.candidate_analytics_url;
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4a4dc12a68044c94e6bd351a707de2efb3301692"
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://services.cognicue.in/api/token/obtain/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        const json_result = JSON.parse(result);
        console.log("json_result", json_result);
        const access_resp = json_result.access;
        console.log("access_resp", access_resp);
        console.log("current_student_details", current_student_details);

        const final_link = current_report_url + "?access=" + access_resp;
        console.log("final_link", final_link);
        //window.location.href = final_link;
        window.open(
          final_link,
          "_blank" // <- This is what makes it open in a new window.
        );
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <Wrapper>
        <div className="white-bg" style={{ textAlign: "center" }}>
          <div style={{ paddingTop: "50px" }} className="description">
            <h2>Take free communication challenge</h2>
          </div>
          <div className="image-section">
            <img
              width="300px"
              style={{ borderRadius: "230px" }}
              src="https://cdn.mygreenhorn.in/MediaAssets/Images/avatar2.png"
              alt="Illustration"
            />
          </div>
          <div
            style={{ paddingBottom: "10px", paddingTop: 20 }}
            className="image-section"
          >
            <a
              className="e-btn"
              target="_blank"
              rel="noopener noreferrer"
              href="https://communicationtest.lifeskillsbharat.com/"
            >
              Start
            </a>
          </div>
        </div>
      </Wrapper>
    </>
  );
};
export default QuickInterview;
